<template>
    <ul class="l_board_list">
        <li v-for="(item, i) in this.orderedList" :key="i"
            @click="openDetail(item.notice_pk)"
        >
            <div>
                <p class="num">{{ item.fixed_yn == 'true' ? '중요' : (this.page-1)*this.maxShowList+i+1-this.fixedList.length}}</p>
                <p class="title">{{item.title}}</p>
                <p class="date">{{dateFormat(item.enrollment_date)}}<span>{{timeFormat(item.enrollment_date)}}</span></p>
            </div>
        </li>
        <div v-if="this.orderedList.length == 0" class="c_default_text">공지사항이 없습니다.</div>
    </ul>
    <!-- 공지사항 리스트 END -->

    <Pagination
        :page="page"
        :list="list"
        :maxShowPage="maxShowPage"
        :maxShowList="maxShowList"
    />
</template>

<script>
import Pagination from '@/components/customer/Pagination';

import { dateFormat } from '@/utils/formatting/dateFormat';

export default {
    name : 'NoticeList',
    components : {
        Pagination,
    },
    props:{
        page : String,
    },
    mixins : [ dateFormat ],
    computed : {
        fixedList(){
            return this.list.filter(el=>el.fixed_yn == 'true');
        },
        notFixedList(){
            return this.list.filter(el=>el.fixed_yn != 'true')
        },
        orderedList(){
            return this.fixedList.concat(this.notFixedList).slice((this.page - 1)*this.maxShowList, (this.page)*this.maxShowList);
        }
    },
    data(){
        return {
            list : [],
            maxShowPage : 10,   // 한 화면에 보일 최대 페이지수
            maxShowList : 10,   // 한 페이지에 보일 최대 리스트 수
        }
    },
    created(){
        // 진입 시 페이지 page 쿼리 없거나 page<=0이면 page=1 쿼리 페이지로 교체 (최대는 fetchBoardData안에 설정해놓음)
        if(!this.page || this.page <= 0){
            this.$router.replace({
                path : this.$route.path,
                query : {
                    page : 1,
                }
            })
        }
        this.fetchNoticeData();
    },
    methods:{
        fetchNoticeData(){
            this.fetchData('/getNotice', (res)=>{
                this.list = res.data;
            })
        },
        openDetail(notice_pk){
            this.$router.push(`/customer/noticeList/detail?pk=${notice_pk}`);
        }
    }
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_customer.css"></style>
