<template>
    <div class="c_content_container">
        <section class="c_section c_inner">
            <h1 class="l_page_title">고객센터</h1>

            <LayoutTabTop 
                v-if="!this.$route.path.includes('/customer/noticeList/detail')"
                :navList="navList"
            />
            
            <router-view></router-view>

        </section>
    </div>
</template>

<script>
import LayoutTabTop from '@/components/LayoutTabTop.vue'

export default {
    name : 'Customer',
    components: { 
        LayoutTabTop,
    },
    data(){
        return {
            navList : [
                { to : '/customer/noticeList', content : '공지사항' },
                { to : '/customer/reseller',  content : '리셀러 신청' },
                { to : '/customer/faq', content : '자주 묻는 질문' },
            ]
        }
    },
}
</script>
