<template>
    <section class="detailBoard">
        <div class="text">
            <div class="title">
                <p>{{this.detailData.data.title}}</p>
                <span>{{dateFormat(this.detailData.data.enrollment_date)}}</span>
            </div>

            <div class="file">
                <div class="default" v-if="this.detailData.file.length == 0">첨부파일이 없습니다.</div>
                <div v-else>
                    <p>첨부파일 : </p>
                    <p class="file_list">
                        <a v-for="(item, index) in this.detailData.file" :key="index"
                            :href="`${item.path}${item.save_name}`"
                            target="_blank" 
                        >{{item.original_name}}<br/></a>    
                    </p>
                </div>
            </div>

            <div class="con" v-html="this.detailData.data.content"></div>
        </div>

        <button @click="this.$router.go(-1)" class="c_btn_primary-fill btn_back">이전으로</button>
    </section>
</template>

<script>
import { dateFormat } from '@/utils/formatting/dateFormat';

export default {
    name : 'NoticeDetail',
    props : {
        pk : String,
    },
    mixins : [ dateFormat ],
    data(){
        return {
            detailData : {
                data: {},
                file: [],
            }
        }
    },
    created(){
        this.fetchDetailData();
    },
    methods:{
        fetchDetailData(){
            this.fetchData('/viewNotice', (data)=>{
                this.detailData = data;
            }, {
                pk : this.pk,
            })
        }
    }
}
</script>

<style scoped src="@/assets/css/layout_customer.css"></style>
