import store from '../store'

export const fetchDataEl = {
    methods: {
        // 해당 url로 데이터 요청 후 응답오면 콜백 실행
        fetchDataEl(url, callback=()=>{}, El, params={}){
            El.classList.add('c_loading');  // 로딩 시작
            this.axios.get(url, { params : params })
            .then(res=>{
                // console.log(res.data);
                El.classList.remove('c_loading');  // 로딩 끝
                if(res.data.success){
                    callback(res.data);
                } else {
                    store.commit('error', '데이터를 받아오는것에 실패했습니다.');
                }
            }).catch(error=>{
                store.commit('error', '데이터를 받아오는 중 문제가 생겼습니다.');
                El.classList.remove('c_loading');  // 로딩 끝
                console.error(error);
            });
        }
    },
}