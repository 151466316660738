<template>
    <!-- 페이지 네비게이션 START -->
    <ul class="page_nav">
        <li class="first"
            @click="setPage(1)"
            :class="this.page <= 1 || !this.page ? 'disable' : ''"
        ></li>
        <li class="prev"
            @click="setPage(this.page - 1)"
            :class="this.page <= 1 || !this.page ? 'disable' : ''"
        ></li>
        <li v-if="this.list.length <= this.maxShowList" class="on">1</li>
        <template v-else>
            <!-- Math.min( 모든 페이지 수 - 이전화면페이지들, 한 화면 최대 페이지수 )만큼 반복 -->
            <li
                v-for="i in Math.min(this.totalPage - this.beforePage, this.maxViewPage)"
                :key="i"
                :class="(this.beforePage + i) == this.page ? 'on' : ''"

                @click="setPage(Math.floor(this.beforePage + i))"
            >
                {{this.beforePage + i}}
            </li>
        </template>
        
        <!-- <li class="m_hidden">10</li> -->
        <li class="next"
            @click="setPage(this.page*1 + 1)"
            :class="!this.page || this.page >= this.totalPage ? 'disable' : ''"
        ></li>
        <li class="last"
            @click="setPage(this.totalPage)"
            :class="!this.page || this.page >= this.totalPage ? 'disable' : ''"
        ></li>
    </ul>
    <!-- 페이지 네비게이션 END -->
</template>

<script>
export default {
    props:{
        page : String,
        list : Array,
        maxShowPage : Number,
        maxShowList : Number,
    },
    data(){
        return {
            viewPage : this.maxShowPage,
        }
    },
    computed : {
        totalPage(){    // 총 페이지 수
            return this.list.length == 0 ? 0
                : Math.ceil(this.list.length/this.maxShowList);
        },
        beforePage(){   // 이전화면 페이지들 개수
            return this.page <= this.maxViewPage ? 0
                : Math.floor((this.page-1)/this.maxViewPage) * this.maxViewPage;
        },
        maxViewPage(){
            return Math.min(this.maxShowPage, this.viewPage)
        }
    },
    mounted(){
        this.getMaxViewPage();
        window.addEventListener('resize', this.getMaxViewPage)
    },
    unmounted(){
        window.removeEventListener('resize', this.getMaxViewPage)
    },
    methods : {
        // 페이지 변경
        setPage(num){
            // 0보단 크고, 최대 페이지 이하일 때
            if(num > 0 && num <= Math.ceil(this.list.length/this.maxShowList)){
                this.$router.push({
                    path : this.$route.path,
                    query : {
                        page : num,
                    }
                });
            }
        },
        getMaxViewPage(){
            if(window.innerWidth < 768){
                this.viewPage = 5;
            } else {
                this.viewPage = this.maxShowPage;
            }
        },
    }
}
</script>

<style scoped>
/* 게시판 네비게이션 START */
.page_nav {
    margin: var(--margin-mid) 0;
    text-align: center;
    display: flex;
    justify-content: center;
}
.page_nav li {
    margin: 0 0.2rem;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    display: inline-block;
    vertical-align: middle;
    font-size: var(--font-small);
    color: var(--default-font-color);
    cursor: pointer;
    box-sizing: border-box;
    background-position: center;
    background-size: 1.8rem;
    background-repeat: no-repeat;
}
.page_nav li:hover {
    color: var(--primary-color);
    font-weight: var(--font-w-bold);
    transition: 0.2s;
	transition-timing-function: ease-out;
}
.page_nav li.on {
    color: var(--primary-color-contrast);
    background-color: var(--primary-color);
    border-radius: var(--border-radius-full);
    font-weight: 400;
    text-decoration: none;
    cursor: default;
}
.page_nav li.disable{
    cursor: default;
    /* color: var(--disabled-color) !important; */
}
.page_nav li.first {
    background-image:url("~@/assets/image/common/icon_nav_first.svg");
}
.page_nav li.first:hover {
    color: var(--primary-color-contrast);
    background-image:url("~@/assets/image/common/icon_nav_first_hover.svg");
}
.page_nav li.prev {
    margin-right: 1rem;
    background-image:url("~@/assets/image/common/icon_nav_prev.svg");
}
.page_nav li.prev:hover {
    color: var(--primary-color-contrast);
    background-image:url("~@/assets/image/common/icon_nav_prev_hover.svg");
}
.page_nav li.next {
    margin-left: 1rem;
    background-image:url("~@/assets/image/common/icon_nav_next.svg");
}
.page_nav li.next:hover {
    color: var(--primary-color-contrast);
    background-image:url("~@/assets/image/common/icon_nav_next_hover.svg");
}
.page_nav li.last {
    background-image:url("~@/assets/image/common/icon_nav_last.svg");
}
.page_nav li.last:hover {
    color: var(--primary-color-contrast);
    background-image:url("~@/assets/image/common/icon_nav_last_hover.svg");
}
/* 게시판 네비게이션 END */
</style>